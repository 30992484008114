<template>
  <modal-brandme :show_modal="show" :key="change_modal">
    <template #title="">
      <span>{{$t('update_sentiments')}}</span>
    </template>
    <template #body="">
      <validation-observer  #default="{ invalid }">
        <b-form>
          <b-form-group :label="$t(`stats.neutral`)">
            <validation-provider rules="required">
              <b-form-input v-model="neutral" type="number" :placeholder="$t(`stats.neutral`)"></b-form-input>
            </validation-provider>
          </b-form-group>

          <b-form-group :label="$t(`stats.negative`)">
            <validation-provider rules="required">
              <b-form-input v-model="negative" type="number" :placeholder="$t(`stats.negative`)"></b-form-input>
            </validation-provider>
          </b-form-group>

          <b-form-group :label="$t(`stats.positive`)">
            <validation-provider rules="required">
              <b-form-input v-model="positive" type="number" :placeholder="$t(`stats.positive`)"></b-form-input>
            </validation-provider>
          </b-form-group>

          <b-button class="mt-2 mb-1 float-right blue-button" :disabled="invalid" variant="blue-button" @click="updateSentiments">{{$t('update')}}</b-button>
        </b-form>
      </validation-observer>
    </template>
  </modal-brandme>
</template>

<script>
import ModalBrandme from '@/views/components/modal/ModalBrandme.vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { BForm, BFormInput, BButton, BFormGroup } from 'bootstrap-vue';
import service_manage from '@/services/manage_campaign'

export default {
  name: 'FormSentiment',
  props: {
    show_form: {
      type: Boolean,
      default: false
    },
    sentiments: {
      type: Array,
      default: () => []
    }
  },
  components: {
    ModalBrandme,
    BForm,
    BFormInput,
    BButton,
    BFormGroup,
    ValidationProvider,
    ValidationObserver
  },
  data() {
    return {
      show: this.show_form,
      change_modal: false,
      neutral: 0,
      negative: 0,
      positive: 0
    }
  },
  created() {
    this.sentiments.forEach(sentiment => {
      switch (sentiment.real_text) {
      case 'neutral':
        this.neutral = sentiment.real_prc
        break;
      case 'negative':
        this.negative = sentiment.real_prc
        break;
      case 'positive':
        this.positive = sentiment.real_prc
        break;
      }
    })
  },
  methods: {
    errorUpdate(title, text) {
      this.$swal({
        title,
        text,
        icon: 'danger',
        customClass: {
          confirmButton: 'btn btn-danger'
        },
        buttonsStyling: false,
        heightAuto: false,
      })
    },
    updateSentiments() {
      const total_prc = parseInt(this.negative) + parseInt(this.positive) + parseInt(this.neutral)

      if (total_prc > 100) {
        this.errorUpdate(this.$t('campaigns.error'), this.$t('more_than_100'))
      } else if (total_prc < 100) {
        this.errorUpdate(this.$t('campaigns.error'), this.$t('less_than_100'))
      } else {
        const form_data = new FormData()
        form_data.append('negative', parseInt(this.negative))
        form_data.append('positive', parseInt(this.positive))
        form_data.append('neutral', parseInt(this.neutral))

        service_manage.updateSentiment(this.$route.params.uuid, form_data)
          .then(response => {
            this.$emit('updatedSentiments', response)
            this.show = false
            this.change_modal = !this.change_modal
          })
      }
    }
  }
}
</script>
